import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login } from "../actions/userActions";
import {
  Card,
  CardTitle,
  CardText,
  CardActions,
  TextField,
  Button,
  CircularProgress,
  Toolbar,
  MenuButton
} from "react-md";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
      username: "",
      password: ""
    };
  }

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  onLogin = e => {
    e.preventDefault();

    const loginData = {
      username: this.state.username,
      password: this.state.password
    };

    this.props.login(loginData);
  };

  onMenuClick = e => {
    if (e.target && e.target.innerText) {
      switch (e.target.innerText) {
        default:
          break;
        case "Scoreboard":
          this.setState({
            redirect: "scoreboard"
          });
          break;
      }
    }
  };
  render() {
    if (this.state.redirect) {
      switch (this.state.redirect) {
        default:
          break;
        case "scoreboard":
          return <Redirect to="/offline" />;
      }
    }

    const loading = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <CircularProgress id="PurchaseFormLoading" />
          </div>
        </div>
      </React.Fragment>
    );

    const redirect = <Redirect to="/online" />;

    const step01 = (
      <React.Fragment>
        <Toolbar
          fixed
          title={"Here we are!"}
          actions={
            <MenuButton
              id={"onlineMenuButton"}
              icon
              // className={className}
              menuItems={["Scoreboard"]}
              onMenuClick={this.onMenuClick}
            >
              more_vert
            </MenuButton>
          }
        />
        <Card
          style={{ maxWidth: 640 }}
          className="md-block-centered md-toolbar-relative--prominent"
        >
          <CardTitle title="Login" subtitle="" />
          <CardText>
            <TextField
              id="loginUsername"
              label="Username"
              placeholder=""
              name="username"
              value={this.state.username}
              onChange={this.onChange}
            />
            <TextField
              id="loginPassword"
              label="Password"
              placeholder=""
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.onChange}
            />
          </CardText>
          <CardActions>
            <Button raised primary onClick={this.onLogin}>
              Login
            </Button>
          </CardActions>
        </Card>
      </React.Fragment>
    );

    return this.props.fetching_user
      ? loading
      : this.props.users.api_key
      ? redirect
      : step01;
  }
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  users: state.users.item,
  fetching_user: state.users.fetching
});

export default connect(
  mapStateToProps,
  {
    login
  }
)(Login);
