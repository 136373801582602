import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Button,
  CircularProgress,
  Chip,
  Autocomplete
} from "react-md";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import { fetchPurchases } from "../actions/pruchaseActions";
import {
  fetchTeams,
  deleteTeam,
  createTeam,
  updateTeam
} from "../actions/teamActions";
import { fetchMaterials } from "../actions/materialActions";
import { fetchUnits } from "../actions/unitActions";
import { fetchCongregations } from "../actions/congregationActions";
import {
  getTeamById,
  getAttendeeNameById,
  getAttendeesByTeamId,
  getCongregationNameById,
  getAttendeeAgeById,
  getAttendeeCongregationIdById,
  getAttendeeGenderById
} from "../functions";

class TeamForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id ? this.props.id : null,
      fetching: false,
      name: "",
      attendees: [],
      filteredAttendees: [],
      state: null
    };
  }

  componentWillMount = () => {
    // if (this.props.id) {
    this.props.fetchTeams();
    this.props.fetchAttendees();
    this.props.fetchPurchases();
    this.props.fetchMaterials();
    this.props.fetchUnits();
    this.props.fetchCongregations();
    // }
  };

  componentWillReceiveProps = nextProps => {
    if (
      !nextProps.fetching_attendees &&
      !nextProps.fetching_purchases &&
      !nextProps.fetching_materials &&
      !nextProps.fetching_units &&
      !nextProps.fetching_teams &&
      !nextProps.fetching_congregations
    ) {
      this.setState({
        fetching: false
      });
      if (this.state.id) {
        let team = getTeamById(this.state.id);
        let newState = {};
        if (team) {
          newState = {
            ...newState,
            name: team.name
          };
          this.setNextAttendees(
            getAttendeesByTeamId(this.state.id).map(atte => atte.id)
          );
        }
        this.setState(newState);
      } else {
        this.setNextAttendees([]);
      }
    } else {
      this.setState({
        fetching: true
      });
    }
  };

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  onChangeSelect = (val, index, e, { name }) => {
    this.setState({ [name]: val });
  };

  onSubmit = e => {
    e.preventDefault();

    const team = {
      name: this.state.name,
      attendees: this.state.attendees
    };

    this.setState({ state: "submited" });

    if (this.state.id) {
      this.props.updateTeam(this.state.id, team);
    } else {
      this.props.createTeam(team);
    }
  };

  onDelete = () => {
    this.setState({ state: "submited" });
    this.props.deleteTeam(this.state.id);
  };

  addAttendee = (id, index, matches) => {
    const atte = matches[index];
    const attendees = [...this.state.attendees, atte.id];
    this.setNextAttendees(attendees);
  };

  removeAttendee = atte => () => {
    const attendees = this.state.attendees.slice();
    attendees.splice(attendees.indexOf(atte), 1);
    this.setNextAttendees(attendees);
  };

  setNextAttendees = attendees => {
    this.setState({
      attendees: attendees,
      filteredAttendees: this.props.attendees
        .slice()
        .filter(
          atte =>
            attendees.indexOf(atte.id) === -1 &&
            (atte.team_id === null || atte.team_id === this.state.id)
        )
        .map(atte => ({
          ...atte,
          label:
            atte.firstname +
            " " +
            atte.lastname +
            ", " +
            atte.age +
            ", " +
            getCongregationNameById(atte.congregation_id)
        }))
    });
  };

  render() {
    const loading = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <CircularProgress id="PurchaseFormLoading" />
          </div>
        </div>
      </React.Fragment>
    );

    const redirect = <Redirect to="/online/teams" />;

    const atte_age = this.state.attendees.map(atte => getAttendeeAgeById(atte));
    const atte_gender = this.state.attendees.map(atte =>
      getAttendeeGenderById(atte)
    );
    const atte_congr = this.state.attendees.map(atte =>
      getAttendeeCongregationIdById(atte)
    );

    const step01 = (
      <React.Fragment>
        <form id="teamForm" onSubmit={this.onSubmit} className="md-grid">
          <TextField
            id="teamFormName"
            label="Name"
            placeholder=""
            className="md-cell md-cell--12 md-cell--bottom"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
            maxLength={100}
          />
          <div className="md-cell md-cell--12 md-cell--bottom">
            {this.state.attendees.map(atte => (
              <Chip
                key={atte}
                className="state-chip"
                onClick={this.removeAttendee(atte)}
                removable
                label={
                  getAttendeeNameById(atte) +
                  ", " +
                  getAttendeeAgeById(atte) +
                  ", " +
                  getCongregationNameById(getAttendeeCongregationIdById(atte))
                }
              />
            ))}
          </div>

          <Autocomplete
            id="states-autocomplete"
            label="Select attendees"
            className="md-cell md-cell--12 md-cell--bottom"
            data={this.state.filteredAttendees}
            dataLabel="label"
            dataValue="id"
            onAutocomplete={this.addAttendee}
            clearOnAutocomplete
            deleteKeys="id"
          />

          <p className="md-cell md-cell--4 md-cell--bottom">
            <b>Age:</b> {atte_age.filter(age => age >= 0 && age < 15).length} |{" "}
            {atte_age.filter(age => age >= 15 && age < 20).length} |{" "}
            {atte_age.filter(age => age >= 20 && age < 25).length} |{" "}
            {atte_age.filter(age => age >= 25 && age < 30).length} |{" "}
            {atte_age.filter(age => age >= 30).length} |{" "}
          </p>
          <p className="md-cell md-cell--4 md-cell--bottom">
            <b>Gender:</b>{" "}
            {["f", "m", "d"].map(
              elm => atte_gender.filter(gender => gender === elm).length + " | "
            )}
          </p>
          <p className="md-cell md-cell--4 md-cell--bottom">
            <b>Congregation:</b>{" "}
            {[...new Set(atte_congr)].map(
              elm => atte_congr.filter(congr => congr === elm).length + " | "
            )}
            {Array.from(
              Array(atte_congr.length - [...new Set(atte_congr)].length)
            ).map((_, i) => "0 | ")}
          </p>

          {!this.props.id ? (
            <React.Fragment>
              <Button
                raised
                primary
                className="md-cell md-cell--12"
                type="submit"
              >
                Submit
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                raised
                primary
                className="md-cell md-cell--6"
                type="submit"
              >
                Edit
              </Button>
              <Button
                raised
                className="md-cell md-cell--6"
                onClick={this.onDelete}
              >
                Delete
              </Button>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    );

    return (
      <div>
        {this.state.fetching
          ? loading
          : this.state.state === "submited"
          ? redirect
          : step01}
      </div>
    );
  }
}

TeamForm.propTypes = {
  history: PropTypes.object.isRequired,
  id: PropTypes.number,
  fetchAttendees: PropTypes.func.isRequired,
  fetchPurchases: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  createTeam: PropTypes.func.isRequired,
  fetchMaterials: PropTypes.func.isRequired,
  fetchUnits: PropTypes.func.isRequired,
  fetchCongregations: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  attendees: state.attendees.items,
  fetching_attendees: state.attendees.fetching,
  fetching_purchases: state.purchases.fetching,
  fetching_units: state.units.fetching,
  fetching_materials: state.materials.fetching,
  fetching_congregations: state.congregations.fetching
});

export default connect(
  mapStateToProps,
  {
    fetchAttendees,
    fetchPurchases,
    fetchTeams,
    deleteTeam,
    updateTeam,
    createTeam,
    fetchMaterials,
    fetchUnits,
    fetchCongregations
  }
)(TeamForm);
