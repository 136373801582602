import * as types from "./types";
import { getApiKey, handleFetchResponse } from "../functions";

export const fetchTeams = () => dispatch => {
  dispatch({ type: types.FETCHING_TEAMS });

  fetch(types.API_ROOT + "teams")
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.FETCH_TEAMS,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const createTeam = teamData => dispatch => {
  dispatch({ type: types.FETCHING_TEAMS });

  fetch(types.API_ROOT + "teams", {
    method: "POST",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "content-type": "application/json"
    },
    body: JSON.stringify(teamData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.NEW_TEAM,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const updateTeam = (id, teamData) => dispatch => {
  dispatch({ type: types.FETCHING_TEAMS });

  fetch(types.API_ROOT + "teams/" + id, {
    method: "PUT",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(teamData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.UPDATE_TEAM,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const deleteTeam = id => dispatch => {
  dispatch({ type: types.FETCHING_TEAMS });

  fetch(types.API_ROOT + "teams/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    }
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.DELETE_TEAM,
        payload: id
      })
    )
    .catch(() => {});
};
