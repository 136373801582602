import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchTeams } from "../actions/teamActions";
import {
  Toolbar,
  List,
  ListItem,
  Divider,
  Card,
  CardTitle,
  MenuButton
} from "react-md";
import { Redirect } from "react-router-dom";
import moment from "moment";

class Offline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null
    };
  }

  componentWillMount() {
    this.props.fetchTeams();
  }

  onMenuClick = e => {
    if (e.target && e.target.innerText) {
      switch (e.target.innerText) {
        default:
          break;
        case "Login":
          this.setState({
            redirect: "login"
          });
          break;
      }
    }
  };

  render() {
    if (this.state.redirect) {
      switch (this.state.redirect) {
        default:
          break;
        case "login":
          return <Redirect to="/login" />;
      }
    }

    const teamsItems = this.props.teams
      .sort((a, b) => a.points < b.points)
      .map(team => (
        <React.Fragment key={team.id}>
          <ListItem
            primaryText={team.name}
            rightIcon={
              <p align="right">
                <b>{team.points} P.</b>
              </p>
            }
          />
          <Divider />
        </React.Fragment>
      ));

    return (
      <React.Fragment>
        <Toolbar
          fixed
          title={"Here we are!"}
          actions={
            <MenuButton
              id={"onlineMenuButton"}
              icon
              // className={className}
              menuItems={["Login"]}
              onMenuClick={this.onMenuClick}
            >
              more_vert
            </MenuButton>
          }
        />
        {this.props.teams.length > 0 ? (
          <Card
            style={{ maxWidth: 640 }}
            className="md-block-centered md-toolbar-relative--prominent"
          >
            <CardTitle
              title="Scoreboard"
              subtitle={
                "Last Update " +
                moment(this.props.teams[0].points_date).format("HH:mm")
              }
            />
            <List>{teamsItems}</List>
          </Card>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

Offline.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  teams: state.teams.items,
  fetching_teams: state.teams.fetching
});

export default connect(
  mapStateToProps,
  {
    fetchTeams
  }
)(Offline);
