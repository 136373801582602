import React, { Component } from "react";
import PropTypes from "prop-types";
import { Toolbar } from "react-md";
import NavBack from "../components/NavBack";
import TeamsGenerator from "../components/TeamsGenerator";

class TeamsGenerate extends Component {
  render() {
    return (
      <React.Fragment>
        <Toolbar
          themed
          fixed
          nav={<NavBack history={this.props.history} />}
          title="Generate Teams"
        />
        <div className="md-toolbar-relative">
          <TeamsGenerator history={this.props.history} />
        </div>
      </React.Fragment>
    );
  }
}

TeamsGenerate.propTypes = {
  history: PropTypes.object.isRequired
};

export default TeamsGenerate;
