import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, SelectField, Button, CircularProgress } from "react-md";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import {
  fetchPurchases,
  deletePurchase,
  createPurchase,
  updatePurchase
} from "../actions/pruchaseActions";
import { fetchTeams } from "../actions/teamActions";
import { fetchMaterials } from "../actions/materialActions";
import { fetchUnits } from "../actions/unitActions";
import {
  getAttendeeById,
  getTeamNameById,
  getMaterialUnitIdById,
  getUnitNameById,
  getMaterialCostById,
  getPurchaseById
} from "../functions";
import QrCodeDialog from "./QrCodeDialog";
import QrCodeManuallyDialog from "./QrCodeManuallyDialog";

class PurchaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id ? this.props.id : null,
      fetching: false,
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false,
      attendee_id: null,
      firstname: "",
      lastname: "",
      team_id: 0,
      material_id: 0,
      amount: 0,
      state: null
    };
  }

  componentWillMount = () => {
    if (this.props.id) {
      this.props.fetchAttendees();
      this.props.fetchPurchases();
      this.props.fetchTeams();
      this.props.fetchMaterials();
      this.props.fetchUnits();
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      !nextProps.fetching_attendees &&
      !nextProps.fetching_purchases &&
      !nextProps.fetching_materials &&
      !nextProps.fetching_units &&
      !nextProps.fetching_teams
    ) {
      this.setState({
        fetching: false
      });
      if (this.state.state === "scanned") {
        const atte = getAttendeeById(this.state.attendee_id);
        if (atte) {
          this.setState({
            firstname: atte ? atte.firstname : "",
            lastname: atte ? atte.lastname : "",
            team_id: atte ? atte.team_id : 0
          });
        } else {
          this.setState({
            attendee_id: null
          });
        }
      } else if (this.state.id) {
        let pur = getPurchaseById(this.state.id);
        let atte = null;
        let newState = {};
        if (pur) {
          newState = {
            ...newState,
            attendee_id: pur.attendee_id,
            material_id: pur.material_id,
            amount: pur.amount
          };
          atte = getAttendeeById(pur.attendee_id);
        }
        if (atte) {
          newState = {
            ...newState,
            firstname: atte ? atte.firstname : "",
            lastname: atte ? atte.lastname : "",
            team_id: atte ? atte.team_id : 0
          };
        }
        this.setState(newState);
      }
    } else {
      this.setState({
        fetching: true
      });
    }
  };

  onQrCodeScan = val => {
    const qrCodeId = parseInt(val);
    this.setState({
      state: "scanned",
      attendee_id: qrCodeId,
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false
    });
    this.props.fetchAttendees();
    this.props.fetchPurchases();
    this.props.fetchTeams();
    this.props.fetchMaterials();
    this.props.fetchUnits();
  };

  onQrCodeCancel = () => {
    this.setState({
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false,
      attendee_id: null
    });
  };

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  onChangeSelect = (val, index, e, { name }) => {
    this.setState({ [name]: val });
  };

  onSubmit = e => {
    e.preventDefault();

    const purchase = {
      attendee_id: this.state.attendee_id,
      material_id: this.state.material_id,
      amount: this.state.amount
    };

    this.setState({ state: "submited" });

    if (this.state.id) {
      this.props.updatePurchase(this.state.id, purchase);
    } else {
      this.props.createPurchase(purchase);
    }
  };

  onDelete = () => {
    this.setState({ state: "submited" });
    this.props.deletePurchase(this.state.id);
  };

  render() {
    const loading = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <CircularProgress id="PurchaseFormLoading" />
          </div>
        </div>
      </React.Fragment>
    );

    const redirect = <Redirect to="/online/purchases" />;

    const step01 = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <h2>Step 1 - Attendee</h2>
          </div>
          <Button
            raised
            primary
            className="md-cell md-cell--6"
            onClick={() => this.setState({ qrCodeDialogShow: true })}
          >
            Scan QR-Code
          </Button>
          <QrCodeDialog
            show={this.state.qrCodeDialogShow}
            onScan={this.onQrCodeScan}
            onCancel={this.onQrCodeCancel}
          />
          <Button
            raised
            className="md-cell md-cell--6"
            onClick={() => this.setState({ qrCodeManuallyDialogShow: true })}
          >
            Enter manually
          </Button>
          <QrCodeManuallyDialog
            show={this.state.qrCodeManuallyDialogShow}
            onScan={this.onQrCodeScan}
            onCancel={this.onQrCodeCancel}
          />
        </div>
      </React.Fragment>
    );
    const step02 = (
      <React.Fragment>
        <form id="purchaseForm" onSubmit={this.onSubmit} className="md-grid">
          <div className="md-cell md-cell--12">
            <h2>Step 1 - Attendee</h2>
          </div>

          <TextField
            id="purchaseFormFirstname"
            label="Firstname"
            placeholder=""
            className="md-cell md-cell--6 md-cell--bottom"
            name="firstname"
            value={this.state.firstname}
            maxLength={100}
            disabled
          />

          <TextField
            id="purchaseFormLastname"
            label="Lastname"
            placeholder=""
            className="md-cell md-cell--6 md-cell--bottom"
            name="lastname"
            value={this.state.lastname}
            maxLength={100}
            disabled
          />

          <TextField
            id="purchaseFormTeam"
            label="Team"
            placeholder=""
            className="md-cell md-cell--12 md-cell--bottom"
            name="team_name"
            value={getTeamNameById(this.state.team_id)}
            maxLength={100}
            disabled
          />

          <div className="md-cell md-cell--12">
            <h2>Step 2 - Material</h2>
          </div>

          <SelectField
            id="purchaseFormMaterial"
            label="Material"
            placeholder="Select ..."
            className="md-cell md-cell--4"
            menuItems={this.props.materials.map(material => ({
              label: <div>{material.name}</div>,
              value: material.id
            }))}
            name="material_id"
            value={this.state.material_id}
            onChange={this.onChangeSelect}
            simplifiedMenu={false}
          />

          <TextField
            id="purchaseFormAmount"
            label={
              "Amount (" +
              getUnitNameById(getMaterialUnitIdById(this.state.material_id)) +
              ")"
            }
            placeholder=""
            className="md-cell md-cell--4 md-cell--bottom"
            name="amount"
            type="number"
            step={0.01}
            min={0.01}
            max={10000}
            value={this.state.amount}
            onChange={this.onChange}
          />

          <TextField
            id="purchaseFormCosts"
            label="Costs"
            placeholder=""
            className="md-cell md-cell--4 md-cell--bottom"
            name="costs"
            type="number"
            value={
              this.state.amount * getMaterialCostById(this.state.material_id)
            }
            disabled
          />

          {!this.props.id ? (
            <React.Fragment>
              <Button
                raised
                className="md-cell md-cell--6"
                onClick={() =>
                  this.setState({ attendee_id: null, state: null })
                }
              >
                Back
              </Button>
              <Button
                raised
                primary
                className="md-cell md-cell--6"
                type="submit"
              >
                Submit
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                raised
                primary
                className="md-cell md-cell--6"
                type="submit"
              >
                Edit
              </Button>
              <Button
                raised
                className="md-cell md-cell--6"
                onClick={this.onDelete}
              >
                Delete
              </Button>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    );

    return (
      <div>
        {this.state.fetching
          ? loading
          : this.state.state === "submited"
          ? redirect
          : this.state.id === null && this.state.attendee_id === null
          ? step01
          : step02}
      </div>
    );
  }
}

PurchaseForm.propTypes = {
  history: PropTypes.object.isRequired,
  id: PropTypes.number,
  fetchAttendees: PropTypes.func.isRequired,
  fetchPurchases: PropTypes.func.isRequired,
  deletePurchase: PropTypes.func.isRequired,
  updatePurchase: PropTypes.func.isRequired,
  createPurchase: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  fetchMaterials: PropTypes.func.isRequired,
  fetchUnits: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  materials: state.materials.items,
  fetching_attendees: state.attendees.fetching,
  fetching_purchases: state.purchases.fetching,
  fetching_units: state.units.fetching,
  fetching_materials: state.materials.fetching
});

export default connect(
  mapStateToProps,
  {
    fetchAttendees,
    fetchPurchases,
    deletePurchase,
    updatePurchase,
    createPurchase,
    fetchTeams,
    fetchMaterials,
    fetchUnits
  }
)(PurchaseForm);
