import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import { fetchTeams } from "../actions/teamActions";
import { fetchPurchases } from "../actions/pruchaseActions";
import { fetchMaterials } from "../actions/materialActions";
import { fetchUnits } from "../actions/unitActions";
import {
  getMaterialNameById,
  getAttendeeNameById,
  getAttendeeTeamIdById,
  getTeamNameById,
  getMaterialUnitIdById,
  getUnitNameById,
  getMaterialCostById
} from "../functions";

import moment from "moment";

import { List, ListItem, Divider, FontIcon } from "react-md";

class Purchases extends Component {
  componentWillMount() {
    this.props.fetchMaterials();
    this.props.fetchUnits();
    this.props.fetchPurchases();
    this.props.fetchAttendees();
    this.props.fetchTeams();
  }

  render() {
    const teamsItems = this.props.purchases
      .sort((a, b) =>
        a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
      )
      .map(purchase => (
        <React.Fragment key={purchase.id}>
          <ListItem
            component={RouterLink}
            primaryText={
              purchase.amount +
              " " +
              getUnitNameById(getMaterialUnitIdById(purchase.material_id)) +
              " " +
              getMaterialNameById(purchase.material_id)
            }
            secondaryText={
              getTeamNameById(getAttendeeTeamIdById(purchase.attendee_id)) +
              " | " +
              getAttendeeNameById(purchase.attendee_id) +
              "\n" +
              moment(purchase.created_at).fromNow()
            }
            rightIcon={
              <p align="right">
                <b>
                  {-1 *
                    purchase.amount *
                    getMaterialCostById(purchase.material_id)}{" "}
                  P.
                </b>
              </p>
            }
            threeLines
            to={"/online/purchases/" + purchase.id}
          />
          <Divider />
        </React.Fragment>
      ));
    return (
      <React.Fragment>
        <List className="md-cell md-cell--12 md-paper md-paper--1">
          <ListItem
            key={0}
            component={RouterLink}
            leftIcon={<FontIcon>add_circle</FontIcon>}
            primaryText={"Add new purchase"}
            to={"/online/purchases/add"}
          />
        </List>
        {this.props.purchases.length > 0 ? (
          <List className="md-cell md-cell--12 md-paper md-paper--1">
            {teamsItems}
          </List>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

Purchases.propTypes = {
  fetchMaterials: PropTypes.func.isRequired,
  materials: PropTypes.array.isRequired,
  fetchUnits: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  fetchPurchases: PropTypes.func.isRequired,
  purchases: PropTypes.array.isRequired,
  fetchAttendees: PropTypes.func.isRequired,
  attendees: PropTypes.array.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  purchases: state.purchases.items,
  attendees: state.attendees.items,
  teams: state.teams.items,
  materials: state.materials.items,
  units: state.units.items
});

export default connect(
  mapStateToProps,
  { fetchAttendees, fetchTeams, fetchPurchases, fetchMaterials, fetchUnits }
)(Purchases);
