import store from "./store";
import * as types from "./actions/types";

export const getApiKey = () => {
  return store.getState().users.item.api_key;
};

export const handleFetchResponse = (res, dispatch) => {
  switch (res.status) {
    default:
      break;
    case 401:
      dispatch({ type: types.LOGOUT });
      throw new Error("401");
  }
  return res;
};

export const getCongregationById = id => {
  const cong = store.getState().congregations.items.find(d => d.id === id);
  if (!cong) {
    return null;
  }
  return cong;
};

export const getCongregationNameById = id => {
  const cong = getCongregationById(id);
  if (!cong) {
    return "";
  }
  return cong.name;
};

export const getCongregationDistrictIdById = id => {
  const cong = getCongregationById(id);
  if (!cong) {
    return 0;
  }
  return cong.district_id;
};

export const getDistrictById = id => {
  const dist = store.getState().districts.items.find(d => d.id === id);
  if (!dist) {
    return null;
  }
  return dist;
};

export const getDistrictNameById = id => {
  const dist = getDistrictById(id);
  if (!dist) {
    return "";
  }
  return dist.name;
};

export const getAttendeeById = id => {
  const atte = store.getState().attendees.items.find(a => a.id === id);
  if (!atte) {
    return null;
  }
  return atte;
};

export const getAttendeeNameById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return "";
  }
  return atte.firstname + " " + atte.lastname;
};

export const getAttendeeFirstnameById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return "";
  }
  return atte.firstname;
};

export const getAttendeeLastnameById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return "";
  }
  return atte.lastname;
};

export const getAttendeeTeamIdById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return 0;
  }
  return atte.team_id;
};

export const getAttendeeAgeById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return 0;
  }
  return atte.age;
};

export const getAttendeeCongregationIdById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return 0;
  }
  return atte.congregation_id;
};

export const getAttendeeGenderById = id => {
  const atte = getAttendeeById(id);
  if (!atte) {
    return 0;
  }
  return atte.gender;
};

export const getAttendeesByTeamId = id => {
  const atte = store.getState().attendees.items.filter(a => a.team_id === id);
  if (!atte) {
    return [];
  }
  return atte;
};

export const getMaterialById = id => {
  const mat = store.getState().materials.items.find(d => d.id === id);
  if (!mat) {
    return null;
  }
  return mat;
};

export const getMaterialNameById = id => {
  const mat = getMaterialById(id);
  if (!mat) {
    return "";
  }
  return mat.name;
};

export const getMaterialUnitIdById = id => {
  const mat = getMaterialById(id);
  if (!mat) {
    return 0;
  }
  return mat.unit_id;
};

export const getMaterialCostById = id => {
  const mat = getMaterialById(id);
  if (!mat) {
    return 0;
  }
  return mat.cost;
};

export const getTeamById = id => {
  const team = store.getState().teams.items.find(d => d.id === id);
  if (!team) {
    return null;
  }
  return team;
};

export const getTeamNameById = id => {
  const team = getTeamById(id);
  if (!team) {
    return "";
  }
  return team.name;
};

export const getUnitById = id => {
  const unit = store.getState().units.items.find(d => d.id === id);
  if (!unit) {
    return null;
  }
  return unit;
};

export const getUnitNameById = id => {
  const unit = getUnitById(id);
  if (!unit) {
    return "";
  }
  return unit.name;
};

export const getPurchaseById = id => {
  const pur = store.getState().purchases.items.find(p => p.id === id);
  if (!pur) {
    return null;
  }
  return pur;
};
