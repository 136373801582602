export const API_ROOT = "https://1mai-api.julian-zeschko.de/api/";
export const FETCHING = "FETCHING";

export const FETCH_ATTENDEES = "FETCH_ATTENDEES";
export const NEW_ATTENDEE = "NEW_ATTENDEE";
export const UPDATE_ATTENDEE = "UPDATE_ATTENDEE";
export const DELETE_ATTENDEE = "DELETE_ATTENDEE";
export const FETCHING_ATTENDEES = "FETCHING_ATTENDEES";

export const FETCH_CONGREGATIONS = "FETCH_CONGREGATIONS";
export const FETCHING_CONGREGATIONS = "FETCHING_CONGREGATIONS";
export const FETCH_DISTRICTS = "FETCH_DISTRICTS";
export const FETCHING_DISTRICTS = "FETCHING_DISTRICTS";

export const FETCH_TEAMS = "FETCH_TEAMS";
export const NEW_TEAM = "NEW_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const FETCHING_TEAMS = "FETCHING_TEAMS";

export const FETCH_PURCHASES = "FETCH_PURCHASES";
export const NEW_PURCHASE = "NEW_PURCHASE";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const FETCHING_PURCHASES = "FETCHING_PURCHASES";

export const FETCH_MATERIALS = "FETCH_MATERIALS";
export const FETCHING_MATERIALS = "FETCHING_MATERIALS";

export const FETCH_UNITS = "FETCH_UNITS";
export const FETCHING_UNITS = "FETCHING_UNITS";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FETCHING_USERS = "FETCHING_USERS";
