import * as types from "./types";
import { getApiKey, handleFetchResponse } from "../functions";

export const fetchAttendees = () => dispatch => {
  dispatch({ type: types.FETCHING_ATTENDEES });

  fetch(types.API_ROOT + "attendees", {
    headers: {
      Authorization: "bearer " + getApiKey()
    }
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.FETCH_ATTENDEES,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const createAttendee = attendeeData => dispatch => {
  dispatch({ type: types.FETCHING_ATTENDEES });

  fetch(types.API_ROOT + "attendees", {
    method: "POST",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "content-type": "application/json"
    },
    body: JSON.stringify(attendeeData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.NEW_ATTENDEE,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const updateAttendee = (id, attendeeData) => dispatch => {
  dispatch({ type: types.FETCHING_ATTENDEES });

  fetch(types.API_ROOT + "attendees/" + id, {
    method: "PUT",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(attendeeData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.UPDATE_ATTENDEE,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const deleteAttendee = id => dispatch => {
  dispatch({ type: types.FETCHING_ATTENDEES });

  fetch(types.API_ROOT + "attendees/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    }
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.DELETE_ATTENDEE,
        payload: id
      })
    )
    .catch(() => {});
};
