import React, { Component } from "react";
import PropTypes from "prop-types";
import { Toolbar } from "react-md";
import AttendeeForm from "../components/AttendeeForm";
import NavBack from "../components/NavBack";

class AttendeeAdd extends Component {
  render() {
    return (
      <React.Fragment>
        <Toolbar
          themed
          fixed
          nav={<NavBack history={this.props.history} />}
          title="New Attendee"
        />
        <div className="md-toolbar-relative">
          <AttendeeForm history={this.props.history} />
        </div>
      </React.Fragment>
    );
  }
}

AttendeeAdd.propTypes = {
  history: PropTypes.object.isRequired
};

export default AttendeeAdd;
