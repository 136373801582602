import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import { fetchCongregations } from "../actions/congregationActions";
import { fetchDistricts } from "../actions/districtActions";
import { fetchTeams } from "../actions/teamActions";
import {
  getDistrictNameById,
  getCongregationNameById,
  getCongregationDistrictIdById,
  getTeamNameById
} from "../functions";

import { List, ListItem, Divider, FontIcon } from "react-md";

class Attendees extends Component {
  componentWillMount() {
    this.props.fetchAttendees();
    this.props.fetchCongregations();
    this.props.fetchDistricts();
    this.props.fetchTeams();
  }

  render() {
    const attendeesItems = this.props.attendees
      .sort((a, b) =>
        a.firstname < b.firstname
          ? -1
          : a.firstname > b.firstname
          ? 1
          : a.lastname < b.lastname
          ? -1
          : a.lastname > b.lastname
          ? 1
          : a.id < b.id
          ? -1
          : 1
      )
      .map(attendee => (
        <React.Fragment key={attendee.id}>
          <ListItem
            component={RouterLink}
            primaryText={attendee.firstname + " " + attendee.lastname}
            secondaryText={
              getCongregationNameById(attendee.congregation_id) +
              " (" +
              getDistrictNameById(
                getCongregationDistrictIdById(attendee.congregation_id)
              ) +
              "), " +
              attendee.age +
              ", " +
              attendee.gender
            }
            rightIcon={<p align="right">{getTeamNameById(attendee.team_id)}</p>}
            to={"/online/attendees/" + attendee.id}
          />
          <Divider />
        </React.Fragment>
      ));
    return (
      <React.Fragment>
        <List className="md-cell md-cell--12 md-paper md-paper--1">
          <ListItem
            key={0}
            component={RouterLink}
            leftIcon={<FontIcon>add_circle</FontIcon>}
            primaryText={"Add new attendee"}
            to={"/online/attendees/add"}
          />
        </List>
        {this.props.attendees.length > 0 ? (
          <List className="md-cell md-cell--12 md-paper md-paper--1">
            {attendeesItems}
          </List>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

Attendees.propTypes = {
  fetchAttendees: PropTypes.func.isRequired,
  fetchCongregations: PropTypes.func.isRequired,
  fetchDistricts: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  attendees: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  attendees: state.attendees.items,
  congregations: state.congregations.items,
  districts: state.districts.items
});

export default connect(
  mapStateToProps,
  { fetchAttendees, fetchCongregations, fetchDistricts, fetchTeams }
)(Attendees);
