import React, { Component } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import store from "./store";

import Login from "./routes/Login";
import Online from "./routes/Online";
import AttendeeAdd from "./routes/AttendeeAdd";
import AttendeeEdit from "./routes/AttendeeEdit";
import TeamAdd from "./routes/TeamAdd";
import TeamEdit from "./routes/TeamEdit";
import PurchaseAdd from "./routes/PurchaseAdd";
import PurchaseEdit from "./routes/PurchaseEdit";
import TeamsGenerate from "./routes/TeamsGenerate";
import Offline from "./routes/Offline";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            {/* Login */}
            <Route exact path="/login" component={Login} />

            {/* Online */}
            <Route exact path="/online/:tab" component={Online} />
            <Route exact path="/online/attendees/add" component={AttendeeAdd} />
            <Route
              exact
              path="/online/attendees/:id"
              component={AttendeeEdit}
            />
            <Route exact path="/online/teams/add" component={TeamAdd} />
            <Route
              exact
              path="/online/teams/generate"
              component={TeamsGenerate}
            />
            <Route exact path="/online/teams/:id" component={TeamEdit} />
            <Route exact path="/online/purchases/add" component={PurchaseAdd} />
            <Route
              exact
              path="/online/purchases/:id"
              component={PurchaseEdit}
            />
            <Route
              path="/online"
              render={() => <Redirect to="/online/attendees" />}
            />

            {/* Offline */}
            <Route exact path="/offline" component={Offline} />

            {/* Default Redirect */}
            <Route render={() => <Redirect to="/offline" />} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
