import React, { Component } from "react";
import PropTypes from "prop-types";
import { Toolbar } from "react-md";
import PurchaseForm from "../components/PurchaseForm";
import NavBack from "../components/NavBack";

class PurchaseEdit extends Component {
  render() {
    return (
      <React.Fragment>
        <Toolbar
          themed
          fixed
          nav={<NavBack history={this.props.history} />}
          title="Edit Purchase"
        />
        <div className="md-toolbar-relative">
          <PurchaseForm
            history={this.props.history}
            id={parseInt(this.props.match.params.id)}
          />
        </div>
      </React.Fragment>
    );
  }
}

PurchaseEdit.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default PurchaseEdit;
