import * as types from "../actions/types";

const initialState = {
  items: [],
  fetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case types.FETCHING_ATTENDEES:
      return { ...state, fetching: true };
    case types.FETCH_ATTENDEES:
      return { ...state, items: action.payload, fetching: false };
    case types.NEW_ATTENDEE:
      return {
        ...state,
        items: state.items.concat([action.payload]),
        fetching: false
      };
    case types.UPDATE_ATTENDEE:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
        fetching: false
      };
    case types.DELETE_ATTENDEE:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
        fetching: false
      };
  }
}
