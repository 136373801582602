import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-md";

const NavBack = ({ className, history }) => (
  <Button icon className={className} onClick={history.goBack}>
    keyboard_arrow_left
  </Button>
);
NavBack.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};

export default NavBack;
