import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, SelectField, Button, CircularProgress } from "react-md";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  createAttendee,
  updateAttendee,
  deleteAttendee,
  fetchAttendees
} from "../actions/attendeeActions";
import { fetchCongregations } from "../actions/congregationActions";
import { fetchDistricts } from "../actions/districtActions";
import { getDistrictNameById, getAttendeeById } from "../functions";
import QrCodeDialog from "./QrCodeDialog";
import QrCodeManuallyDialog from "./QrCodeManuallyDialog";

class AttendeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false,
      exists: false,
      id: null,
      firstname: "",
      lastname: "",
      age: 20,
      gender: "m",
      congregation: 1,
      state: null
    };

    this.genders = [
      {
        label: "Male",
        value: "m"
      },
      {
        label: "Female",
        value: "f"
      },
      {
        label: "Diverse",
        value: "d"
      }
    ];
  }

  componentWillMount = () => {
    if (this.props.qrCode) {
      this.onQrCodeScan(this.props.qrCode);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      !nextProps.fetching_attendees &&
      !nextProps.fetching_congregations &&
      !nextProps.fetching_districts
    ) {
      this.setState({
        fetching: false
      });
      if (this.state.state === "scanned") {
        const atte = getAttendeeById(this.state.id);
        this.setState({
          exists: atte ? true : false,
          firstname: atte ? atte.firstname : "",
          lastname: atte ? atte.lastname : "",
          age: atte ? atte.age : 20,
          gender: atte ? atte.gender : "m",
          congregation: atte ? atte.congregation_id : 1
        });
      }
    } else {
      this.setState({
        fetching: true
      });
    }
  };

  onQrCodeScan = val => {
    const qrCodeId = parseInt(val);
    this.setState({
      state: "scanned",
      id: qrCodeId,
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false
    });
    this.props.fetchAttendees();
    this.props.fetchCongregations();
    this.props.fetchDistricts();
  };

  onQrCodeCancel = () => {
    this.setState({
      qrCodeDialogShow: false,
      qrCodeManuallyDialogShow: false,
      id: null
    });
  };

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  onChangeSelect = (val, index, e, { name }) => {
    this.setState({ [name]: val });
  };

  onSubmit = e => {
    e.preventDefault();

    const attendee = {
      id: this.state.id,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      age: this.state.age,
      gender: this.state.gender,
      congregation_id: this.state.congregation
    };

    this.setState({ state: "submited" });

    if (this.state.exists) {
      this.props.updateAttendee(this.state.id, attendee);
    } else {
      this.props.createAttendee(attendee);
    }
  };

  onDelete = () => {
    this.setState({ state: "submited" });
    this.props.deleteAttendee(this.state.id);
  };

  render() {
    const loading = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <CircularProgress id="AttendeeFormLoading" />
          </div>
        </div>
      </React.Fragment>
    );

    const redirect = <Redirect to="/online/attendees" />;

    const step01 = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <h2>Step 1 - QR-Code</h2>
          </div>
          <Button
            raised
            primary
            className="md-cell md-cell--6"
            onClick={() => this.setState({ qrCodeDialogShow: true })}
          >
            Scan QR-Code
          </Button>
          <QrCodeDialog
            show={this.state.qrCodeDialogShow}
            onScan={this.onQrCodeScan}
            onCancel={this.onQrCodeCancel}
          />
          <Button
            raised
            className="md-cell md-cell--6"
            onClick={() => this.setState({ qrCodeManuallyDialogShow: true })}
          >
            Enter manually
          </Button>
          <QrCodeManuallyDialog
            show={this.state.qrCodeManuallyDialogShow}
            onScan={this.onQrCodeScan}
            onCancel={this.onQrCodeCancel}
          />
        </div>
      </React.Fragment>
    );
    const step02 = (
      <React.Fragment>
        <form id="attendeeForm" onSubmit={this.onSubmit} className="md-grid">
          {!this.props.qrCode ? (
            <div className="md-cell md-cell--12">
              <h2>Step 2 - Personal information</h2>
            </div>
          ) : (
            ""
          )}

          {!this.props.qrCode && this.state.exists ? (
            <div className="md-cell md-cell--12 md-font-bold">
              An attendee with this QR-Code is already registered!!!
              <br />
              Are you sure to override/edit this attendee?
            </div>
          ) : (
            ""
          )}

          <TextField
            id="attendeeFormQrCode"
            label="QR-Code"
            placeholder=""
            className="md-cell md-cell--12 md-cell--bottom"
            name="id"
            value={this.state.id}
            onChange={this.onChange}
            disabled
          />

          <TextField
            id="attendeeFormFirstname"
            label="Firstname"
            placeholder=""
            className="md-cell md-cell--6 md-cell--bottom"
            name="firstname"
            value={this.state.firstname}
            onChange={this.onChange}
            maxLength={100}
          />

          <TextField
            id="attendeeFormLastname"
            label="Lastname"
            placeholder=""
            className="md-cell md-cell--6 md-cell--bottom"
            name="lastname"
            value={this.state.lastname}
            onChange={this.onChange}
            maxLength={100}
          />

          <TextField
            id="attendeeFormAge"
            label="Age"
            placeholder=""
            className="md-cell md-cell--4 md-cell--bottom"
            name="age"
            type="number"
            step={1}
            min={13}
            max={100}
            value={this.state.age}
            onChange={this.onChange}
          />

          <SelectField
            id="attendeeFormGender"
            label="Gender"
            placeholder="Select ..."
            className="md-cell md-cell--4"
            menuItems={this.genders}
            name="gender"
            value={this.state.gender}
            onChange={this.onChangeSelect}
          />

          <SelectField
            id="attendeeFormCongregation"
            label="Congregation"
            placeholder="Select ..."
            className="md-cell md-cell--4"
            menuItems={this.props.congregations.map(congregation => ({
              label: (
                <div>
                  {congregation.name}{" "}
                  <small>
                    {"("}
                    {getDistrictNameById(congregation.district_id)}
                    {")"}
                  </small>
                </div>
              ),
              value: congregation.id
            }))}
            name="congregation"
            value={this.state.congregation}
            onChange={this.onChangeSelect}
            simplifiedMenu={false}
          />

          {!this.props.qrCode ? (
            <React.Fragment>
              <Button
                raised
                className="md-cell md-cell--6"
                onClick={() => this.setState({ id: null, state: null })}
              >
                Back
              </Button>
              <Button
                raised
                primary
                className="md-cell md-cell--6"
                type="submit"
              >
                Submit
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                raised
                primary
                className="md-cell md-cell--6"
                type="submit"
              >
                Edit
              </Button>
              <Button
                raised
                className="md-cell md-cell--6"
                onClick={this.onDelete}
              >
                Delete
              </Button>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    );

    return (
      <div>
        {this.state.fetching
          ? loading
          : this.state.state === "submited"
          ? redirect
          : this.state.id === null
          ? step01
          : step02}
      </div>
    );
  }
}

AttendeeForm.propTypes = {
  history: PropTypes.object.isRequired,
  qrCode: PropTypes.number,
  fetchAttendees: PropTypes.func.isRequired,
  createAttendee: PropTypes.func.isRequired,
  updateAttendee: PropTypes.func.isRequired,
  fetchCongregations: PropTypes.func.isRequired,
  fetchDistricts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  congregations: state.congregations.items,
  districts: state.districts.items,
  fetching_attendees: state.attendees.fetching,
  fetching_congregations: state.congregations.fetching,
  fetching_districts: state.districts.fetching
});

export default connect(
  mapStateToProps,
  {
    fetchAttendees,
    createAttendee,
    updateAttendee,
    deleteAttendee,
    fetchCongregations,
    fetchDistricts
  }
)(AttendeeForm);
