import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import { fetchTeams } from "../actions/teamActions";
import { getAttendeesByTeamId } from "../functions";

import { List, ListItem, Divider, FontIcon } from "react-md";

class Teams extends Component {
  componentWillMount() {
    this.props.fetchAttendees();
    this.props.fetchTeams();
  }

  render() {
    const teamsItems = this.props.teams
      .sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : a.id < b.id ? -1 : 1
      )
      .map(team => (
        <React.Fragment key={team.id}>
          <ListItem
            component={RouterLink}
            primaryText={team.name}
            secondaryText={
              getAttendeesByTeamId(team.id).length +
                " | " +
                getAttendeesByTeamId(team.id)
                  .map(atte => atte.firstname + " " + atte.lastname)
                  .join(", ") || "0"
            }
            rightIcon={
              <p align="right">
                <b>{team.points} P.</b>
              </p>
            }
            to={"/online/teams/" + team.id}
          />
          <Divider />
        </React.Fragment>
      ));
    return (
      <React.Fragment>
        <List className="md-cell md-cell--12 md-paper md-paper--1">
          <ListItem
            key={0}
            component={RouterLink}
            leftIcon={<FontIcon>add_circle</FontIcon>}
            primaryText={"Add new team"}
            to={"/online/teams/add"}
          />
        </List>
        {this.props.teams.length > 0 ? (
          <List className="md-cell md-cell--12 md-paper md-paper--1">
            {teamsItems}
          </List>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

Teams.propTypes = {
  fetchAttendees: PropTypes.func.isRequired,
  attendees: PropTypes.array.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  attendees: state.attendees.items,
  teams: state.teams.items
});

export default connect(
  mapStateToProps,
  { fetchAttendees, fetchTeams }
)(Teams);
