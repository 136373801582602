import * as types from "../actions/types";

const initialState = {
  item: {},
  online: false,
  fetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case types.FETCHING_USERS:
      return { ...state, fetching: true };
    case types.LOGIN:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, item: action.payload, online: true, fetching: false };
    case types.LOGOUT:
      localStorage.removeItem("user");
      return { ...state, item: {}, online: false, fetching: false };
  }
}
