import * as types from "./types";
import { handleFetchResponse } from "../functions";

export const login = loginData => dispatch => {
  dispatch({ type: types.FETCHING_USERS });

  fetch(types.API_ROOT + "login", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(loginData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: types.LOGIN,
        payload: data.result
      });
    })
    .catch(() => {});
};

export const logout = () => dispatch => {
  dispatch({ type: types.LOGOUT });
};
