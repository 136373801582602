import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from "react-md";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchAttendees } from "../actions/attendeeActions";
import { fetchTeams } from "../actions/teamActions";

class TeamsGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id ? this.props.id : null,
      fetching: false,
      name: "",
      attendees: [],
      state: null
    };
  }

  componentWillMount = () => {
    this.props.fetchTeams();
    this.props.fetchAttendees();
  };

  componentWillReceiveProps = nextProps => {
    if (!nextProps.fetching_attendees && !nextProps.fetching_teams) {
      this.setState({
        fetching: false
      });
    } else {
      this.setState({
        fetching: true
      });
    }
  };

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  onChangeSelect = (val, index, e, { name }) => {
    this.setState({ [name]: val });
  };

  onSubmit = e => {
    e.preventDefault();

    const purchase = {
      attendee_id: this.state.attendee_id,
      material_id: this.state.material_id,
      amount: this.state.amount
    };

    this.setState({ state: "submited" });

    if (this.state.id) {
      this.props.updatePurchase(this.state.id, purchase);
    } else {
      this.props.createPurchase(purchase);
    }
  };

  onDelete = () => {
    this.setState({ state: "submited" });
    this.props.deletePurchase(this.state.id);
  };

  render() {
    const loading = (
      <React.Fragment>
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <CircularProgress id="PurchaseFormLoading" />
          </div>
        </div>
      </React.Fragment>
    );

    const redirect = <Redirect to="/online/purchases" />;

    const atte_ages = this.props.attendees
      .slice(0)
      .sort((a, b) => a.age - b.age);
    const atte_cong = this.props.attendees
      .slice(0)
      .sort((a, b) => a.congregation_id - b.congregation_id);
    const atte_gend = this.props.attendees
      .slice(0)
      .sort((a, b) => (a.gender < b.gender ? -1 : a.gender > b.gender ? 1 : 0));

    const step01 = (
      <React.Fragment>
        <DataTable
          baseId="simple-selectable-table"
          indeterminate
          className="md-cell md-cell--12 md-paper md-paper--1"
        >
          <TableHeader>
            <TableRow>
              <TableColumn grow>Type</TableColumn>
              <TableColumn>Cat. 1</TableColumn>
              <TableColumn>Cat. 2</TableColumn>
              <TableColumn>Cat. 3</TableColumn>
              <TableColumn>Cat. 4</TableColumn>
              <TableColumn>Cat. 5</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableColumn>Age</TableColumn>
              {Array.from(Array(5)).map((_, i) => (
                <TableColumn key={i}>
                  {atte_ages
                    .slice(
                      i === 0
                        ? 0
                        : Math.floor(atte_ages.length / 5) * i +
                            (atte_ages.length % 5 > i - 1
                              ? i - 1
                              : atte_ages.length % 5) +
                            1,
                      Math.floor(atte_ages.length / 5) * (i + 1) +
                        (atte_ages.length % 5 > i ? i : atte_ages.length % 5)
                    )
                    .map(att => att.age)
                    .join(", ")}
                </TableColumn>
              ))}
            </TableRow>
            <TableRow>
              <TableColumn>Congregation</TableColumn>
              {Array.from(Array(5)).map((_, i) => (
                <TableColumn key={i}>
                  {atte_cong
                    .slice(5 * i, 5 * i + 5)
                    .map(att => att.congregation_id)
                    .join(", ")}
                </TableColumn>
              ))}
            </TableRow>
            <TableRow>
              <TableColumn>Gender</TableColumn>
              {Array.from(Array(5)).map((_, i) => (
                <TableColumn key={i}>
                  {atte_gend
                    .slice(5 * i, 5 * i + 5)
                    .map(att => att.gender)
                    .join(", ")}
                </TableColumn>
              ))}
            </TableRow>
          </TableBody>
        </DataTable>
      </React.Fragment>
    );

    return (
      <div>
        {this.state.fetching
          ? loading
          : this.state.state === "submited"
          ? redirect
          : step01}
      </div>
    );
  }
}

TeamsGenerator.propTypes = {
  history: PropTypes.object.isRequired,
  fetchAttendees: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  attendees: state.attendees.items,
  fetching_attendees: state.attendees.fetching,
  fetching_teams: state.teams.fetching
});

export default connect(
  mapStateToProps,
  {
    fetchAttendees,
    fetchTeams
  }
)(TeamsGenerator);
