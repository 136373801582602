import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogContainer, TextField } from "react-md";

class QrCodeManuallyDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: null,
      width: null,
      visible: props.show,
      qrCode: null,
      actions: [
        {
          label: "Cancel",
          secondary: true,
          onClick: this.cancel
        },
        {
          label: "OK",
          primary: true,
          onClick: this.submit
        }
      ]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.show();
    }
  }

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  cancel = () => {
    this.props.onCancel();
    this.hide();
  };

  submit = () => {
    this.props.onScan(this.state.qrCode);
    this.hide();
  };

  onChange = (val, e) => {
    this.setState({ [e.target.name]: val });
  };

  render() {
    return (
      <div>
        <DialogContainer
          id="qrCodeDialogManuallyContainer"
          title={null}
          visible={this.state.visible}
          onHide={this.hide}
          actions={this.state.actions}
          height={this.state.height}
          width={this.state.width}
        >
          <TextField
            id="qrCodeDialogManuallyQrCode"
            label="QR-Code"
            placeholder="Enter ID"
            name="qrCode"
            type="number"
            min={1}
            max={999}
            step={1}
            onChange={this.onChange}
          />
        </DialogContainer>
      </div>
    );
  }
}

QrCodeManuallyDialog.propTypes = {
  onScan: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default QrCodeManuallyDialog;
