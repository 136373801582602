import * as types from "./types";
import { handleFetchResponse } from "../functions";

export const fetchCongregations = () => dispatch => {
  dispatch({ type: types.FETCHING_CONGREGATIONS });

  fetch(types.API_ROOT + "congregations")
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.FETCH_CONGREGATIONS,
        payload: data.result.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      })
    )
    .catch(() => {});
};
