import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Online.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { TabsContainer, Tabs, Tab, Toolbar, MenuButton } from "react-md";
import Attendees from "../components/Attendees";
import Teams from "../components/Teams";
import Purchases from "../components/Purchases";
import { logout } from "../actions/userActions";

class Online extends Component {
  onTabChange = (
    newActiveTabIndex,
    tabId,
    tabControlsId,
    tabChildren,
    event
  ) => {
    switch (newActiveTabIndex) {
      default:
      case 0:
        this.props.history.push("/online/attendees");
        break;
      case 1:
        this.props.history.push("/online/teams");
        break;
      case 2:
        this.props.history.push("/online/purchases");
        break;
      case 3:
        this.props.history.push("/online/voting");
        break;
    }
  };

  defaultTabIndex = () => {
    switch (this.props.match.params.tab) {
      default:
      case "attendees":
        return 0;
      case "teams":
        return 1;
      case "purchases":
        return 2;
      case "voting":
        return 3;
    }
  };

  onMenuClick = e => {
    if (e.target && e.target.innerText) {
      switch (e.target.innerText) {
        default:
          break;
        case "Logout":
          this.props.logout();
          break;
      }
    }
  };

  render() {
    if (!this.props.online) {
      return <Redirect to="/" />;
    }

    return (
      <TabsContainer
        fixed
        themed
        toolbar={
          <Toolbar
            themed
            nav={<div />}
            title="Here we are!"
            actions={
              <MenuButton
                id={"onlineMenuButton"}
                icon
                // className={className}
                menuItems={["Logout"]}
                onMenuClick={this.onMenuClick}
              >
                more_vert
              </MenuButton>
            }
          />
        }
        onTabChange={this.onTabChange}
        defaultTabIndex={this.defaultTabIndex()}
        panelClassName="md-grid"
      >
        <Tabs tabId="tabs" mobile inactiveTabClassName="md-text--secondary">
          <Tab label="Attendees">
            <div className="customTab">
              <Attendees />
            </div>
          </Tab>
          <Tab label="Teams">
            <div className="customTab">
              <Teams />
            </div>
          </Tab>
          <Tab label="Purchases">
            <div className="customTab">
              <Purchases />
            </div>
          </Tab>
          {/* <Tab label="Voting">
            <div className="customTab">
              <Votes />
            </div>
          </Tab> */}
        </Tabs>
      </TabsContainer>
    );
  }
}

Online.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  online: state.users.online
});

export default connect(
  mapStateToProps,
  {
    logout
  }
)(Online);
