import * as types from "./types";
import { handleFetchResponse } from "../functions";

export const fetchUnits = () => dispatch => {
  dispatch({ type: types.FETCHING_UNITS });

  fetch(types.API_ROOT + "units")
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.FETCH_UNITS,
        payload: data.result
      })
    )
    .catch(() => {});
};
