import * as types from "./types";
import { getApiKey, handleFetchResponse } from "../functions";

export const fetchPurchases = () => dispatch => {
  dispatch({ type: types.FETCHING_PURCHASES });

  fetch(types.API_ROOT + "purchases", {
    headers: {
      Authorization: "bearer " + getApiKey()
    }
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.FETCH_PURCHASES,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const createPurchase = purchaseData => dispatch => {
  dispatch({ type: types.FETCHING_PURCHASES });

  fetch(types.API_ROOT + "purchases", {
    method: "POST",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "content-type": "application/json"
    },
    body: JSON.stringify(purchaseData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.NEW_PURCHASE,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const updatePurchase = (id, purchaseData) => dispatch => {
  dispatch({ type: types.FETCHING_PURCHASES });

  fetch(types.API_ROOT + "purchases/" + id, {
    method: "PUT",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(purchaseData)
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.UPDATE_PURCHASE,
        payload: data.result
      })
    )
    .catch(() => {});
};

export const deletePurchase = id => dispatch => {
  dispatch({ type: types.FETCHING_PURCHASES });

  fetch(types.API_ROOT + "purchases/" + id, {
    method: "DELETE",
    headers: {
      Authorization: "bearer " + getApiKey(),
      "Content-Type": "application/json"
    }
  })
    .then(res => handleFetchResponse(res, dispatch))
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: types.DELETE_PURCHASE,
        payload: id
      })
    )
    .catch(() => {});
};
