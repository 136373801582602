import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

let initialState = {
  users: {
    item: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
    online: localStorage.getItem("user") ? true : false,
    fetching: false
  }
};

const apiMiddleware = store => next => action => {
  next(action);
};

const middleware = [apiMiddleware, thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
