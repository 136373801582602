import * as types from "../actions/types";

const initialState = {
  items: [],
  fetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case types.FETCHING_DISTRICTS:
      return { ...state, fetching: true };
    case types.FETCH_DISTRICTS:
      return { ...state, items: action.payload, fetching: false };
  }
}
