import { combineReducers } from "redux";
import attendeeReducer from "./attendeeReducer";
import congregationReducer from "./congregationReducer";
import teamReducer from "./teamReducer";
import purchaseReducer from "./purchaseReducer";
import materialReducer from "./materialReducer";
import unitReducer from "./unitReducer";
import districtReducer from "./districtReducer";
import userReducer from "./userReducer";

export default combineReducers({
  users: userReducer,
  attendees: attendeeReducer,
  congregations: congregationReducer,
  districts: districtReducer,
  teams: teamReducer,
  purchases: purchaseReducer,
  materials: materialReducer,
  units: unitReducer
});
