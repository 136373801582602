import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogContainer } from "react-md";
import QrReader from "react-qr-reader";

class QrCodeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: null,
      width: null,
      visible: props.show,
      actions: [
        {
          label: "Cancel",
          secondary: true,
          onClick: this.cancel
        }
      ]
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.show();
    }
  }

  handleScan = data => {
    if (data) {
      this.hide();
      this.props.onScan(data);
    }
  };

  handleError = err => {
    console.error(err);
  };

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  cancel = () => {
    this.props.onCancel();
    this.hide();
  };

  render() {
    return (
      <div>
        <DialogContainer
          id="qrCodeDialogContainer"
          title={null}
          visible={this.state.visible}
          onHide={this.hide}
          actions={this.state.actions}
          height={this.state.height}
          width={this.state.width}
        >
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "100%" }}
          />
        </DialogContainer>
      </div>
    );
  }
}

QrCodeDialog.propTypes = {
  onScan: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default QrCodeDialog;
